.selected {
    background-color: black !important;
    color: white !important;
    font-weight: 900 !important;
}

.notSelected {
    color: gray !important;
    font-weight: 900 !important;
}

.exchanges {
    width: 245px !important;
    max-height: 28px !important;
    min-height: 28px !important;
    position: absolute !important;
    right: 0 !important;
    margin-right: 45px !important;
}

.exchanges-button-text {
    font-size: 16px;
}

.crypto-market-name {
    margin-left: 45px !important;
    vertical-align: top !important;
    font-weight: 700 !important;
}

.crypto-from-exchange {
    font-weight: 700 !important;
    font-size: 14px !important;
}

.crypto-from-quantity {
    font-weight: 500 !important;
    font-size: 12px !important;
    color: gray !important;
}

.crypto-label {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: gray !important;
    display: inline !important;
    padding: 0px !important;
}

.crypto-label-qty-min {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: black !important;
    display: inline !important;
    margin-right: 11px !important;
    margin-bottom: 0px !important;
    padding: 0px !important;
}

.crypto-label-qty-max {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: black !important;
    display: inline !important;
    margin-top: 0px !important;
    padding: 0px !important;
}

.crypto-qty {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: black !important;
    margin-left: 64px !important;
    margin-bottom: 0px !important;
    padding: 0px !important;
}

.crypto-symbol {
    font-size: 12px !important;
    font-weight: 700 !important;
    margin-left: 64px !important;
    color: gray !important;
    margin-top: 0px !important;
    padding: 0px !important;
}

.crypto-profit {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: black !important;
    margin-top: 9px !important;
    margin-left: 45px !important;
    padding: 0px !important;
}

.crypto-close-button {
    margin-left: 28px !important;
    width: 100px !important;
    border-radius: 54px !important;
    min-height: 28px !important;
    max-height: 28px !important;
}

.crypto-view-button {
    margin-left: 45px !important;
    border-radius: 54px !important;
    width: 28px !important;
    min-height: 20px !important;
    max-height: 20px !important;
}

.button-exchange-keys {
    border-radius: 54px !important;
    margin-bottom: 0.5rem !important;
    width: 100% !important;
    min-height: 20px !important;
    max-height: 20px !important;
}

.labels {
    display: inline-block !important;
}

.exchange-card {
    padding-top: 6px;
    padding-bottom: 12px;
    max-width: 700px;
    margin: auto;
}
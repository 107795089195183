.first-modal-button {
  margin-left: 16px !important;
}
.input-precio, .input-cantidad {
  margin-left: 16px !important;
  margin-bottom: 10px !important;
  border-radius: 28px !important;
  max-width: 90% !important;
}
.variable-total {
  margin-top: 9px !important;
  margin-left: 16px !important;
  color: white !important;
  font-size: 14px !important;
}
.modal-buttons-top {
  border-radius: 54px !important;
  min-height: 20px !important;
  min-width: 45px !important;
  padding: 0px !important;
  margin-top: 5px !important;
  font-size: 11px !important;
  margin-right: 5px !important;
  margin-bottom: 10px !important;
  color: #999;
}
.buy-button {
  border-radius: 54px !important;
  min-width: 100px !important;
  padding: 0px !important;
  position: absolute !important;
  right: 0 !important;
  font-size: 11px !important;
  margin-right: 14px !important;
}
.buy-button-main {
  border-radius: 54px !important;
  min-height: 20px !important;
  min-width: 90% !important;
  margin-left: 16px !important;
  padding: 0px !important;
  font-size: 11px !important;
  margin-right: 14px !important;
}
.sell-button-main {
  border-radius: 54px !important;
  min-height: 20px !important;
  min-width: 90% !important;
  margin-right: 0px !important;
  padding: 0px !important;
  font-size: 11px !important;
  margin-right: 14px !important;
}
.sell-button-main-disabled {
  border-radius: 54px !important;
  min-height: 20px !important;
  min-width: 90% !important;
  margin-right: 0px !important;
  padding: 0px !important;
  font-size: 11px !important;
  margin-right: 14px !important;
  background-color: rgb(61, 6, 6) !important;
  color: rgb(117, 93, 93) !important;
  border: 1px solid rgb(54, 3, 3) !important;
  pointer-events: none !important;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  cursor:se-resize;
  margin:5px;
  border-top: solid 2px #333;
  border-right: solid 2px #333;
  border-bottom: solid 0px #333;
  border-left: solid 0px #333;
}
.order-type-button {
  border-radius: 54px !important;
  min-height: 20px !important;
  max-width: 64px !important;
  margin-left: 16px !important;
  padding: 0px !important;
  font-size: 10px !important;
}
.close-button {
  height: auto !important;
  width: auto !important;
  border-radius: 54px !important;
  margin-top: 16px !important;
  margin-left: 16px !important;
  margin-bottom: 11px !important;
  z-index: 1000 !important;
}
.back-button {
  height: 10px !important;
  width: 54px !important;
  border-radius: 54px !important;
  margin-top: 16px !important;
  margin-left: 16px !important;
  margin-bottom: 11px !important;
  z-index: 1000 !important;
 
}
.close-button-text {
  font-size: 16px !important;
  font-weight: 400 !important;
  z-index: 1000 !important;
}
.flexible-modal {
    max-height: fit-content !important;
    position: absolute;
    z-index: 20000 !important;
    background: rgba(255, 255, 255, 0.05) !important;
    backdrop-filter: blur(9px);
    border-radius: 28px;
    -webkit-box-shadow: 12px 22px 52px -15px rgba(0,0,0,0.54);
    -moz-box-shadow: 12px 22px 52px -15px rgba(0,0,0,0.54);
    box-shadow: 12px 22px 52px -20px rgba(0,0,0,0.28);
  }
.flexible-modal-2 {
    max-height: 72px !important;
    position: absolute;
    z-index: 20000 !important;
    background: rgba(255, 255, 255, 0.05) !important;
    backdrop-filter: blur(9px);
    border-radius: 28px;
    -webkit-box-shadow: 12px 22px 52px -15px rgba(0,0,0,0.54);
    -moz-box-shadow: 12px 22px 52px -15px rgba(0,0,0,0.54);
    box-shadow: 12px 22px 52px -20px rgba(0,0,0,0.28);
    overflow: hidden !important;
  }
  .flexible-modal-3 {
    height: 270px !important;
    position: absolute;
    z-index: 20000 !important;
    background: rgba(255, 255, 255, 0.05) !important;
    backdrop-filter: blur(9px);
    border-radius: 28px;
    -webkit-box-shadow: 12px 22px 52px -15px rgba(0,0,0,0.54);
    -moz-box-shadow: 12px 22px 52px -15px rgba(0,0,0,0.54);
    box-shadow: 12px 22px 52px -20px rgba(0,0,0,0.28);
  }
  .flexible-modal-4 {
    height: 400px !important;
    position: absolute;
    z-index: 20000 !important;
    background: rgba(255, 255, 255, 0.05) !important;
    backdrop-filter: blur(9px);
    border-radius: 28px;
    -webkit-box-shadow: 12px 22px 52px -15px rgba(0,0,0,0.54);
    -moz-box-shadow: 12px 22px 52px -15px rgba(0,0,0,0.54);
    box-shadow: 12px 22px 52px -20px rgba(0,0,0,0.28);
  }
  .flexible-modal-mask {
    display: none !important;
    position: fixed;
    height: 100%;
    background: transparent !important;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }
  .flexible-modal-drag-area{
    background: rgba(22, 22, 333, 0) !important;
    height: 72px;
    position:absolute;
    right:0;
    top:0;
    cursor:move;
  }
  .market-label {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: rgb(179, 179, 179) !important;
    display: inline !important;
    /* position: absolute !important; */
    right: 20 !important;
    margin-top: 20px !important;
    padding: 0px 11px !important;
    border-radius: 54px !important;
  }
  .input-market {
    margin-left: 16px !important;
    margin-bottom: 10px !important;
    border-radius: 28px !important;
    max-width: 90% !important;
    max-height: 28px !important;
    margin-top: 14px !important;
    color: black !important;
  }
  .dropdown-markets {
    margin-right: 16px !important;
    margin-left: 16px !important;
    margin-bottom: 10px !important;
    border-radius: 54px !important;
    max-width: 90%;
    min-width: 90%;
    height: 10px !important;
    max-height: 10px !important;
    min-height: 10px !important;
    margin-top: 9px !important;
    color: black !important;
  }
  #combo-box {
    height: 14px !important;
    font-size: 14px !important;
    color: black !important;
    border-radius: 54px !important;
  }
  .tradingChart {
    height: 1000px !important;
    max-height: 1000px !important;
    min-height: 1000px !important;
  }
  .order {
    margin: 22px 16px 0px !important;
    width: 89%;
  }
  .hide {
    display: none !important;
    opacity: 0 !important;
  }
  .minimize {
    width: 16px !important;
    height: 16px !important;
  }
  .trading-view {
    min-height: 92vh !important;
    height: 92vh !important;
  }
  .input-precio {
    padding: 11px !important;
    width: 100% !important;
    height: 28px !important;
    background-color: white !important;
    color: black !important;
  }
  .input-cantidad {
    padding: 11px !important;
    width: 100% !important;
    height: 28px !important;
  }
.dropdown-exchanges {
    z-index: 1000;
    margin-bottom: 328px;
}
.dropdown-par {
    z-index: 1000;
    margin-bottom: 328px;
}
.hide {
    display: none !important;
}
.tradingview-iframe {
    height: 100vh !important;
    overflow: hidden !important;
}
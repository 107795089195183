/* ----------------------------------------------
  Generated by AnimatiSS
  Licensed under FreeBSD License
  URL: https://xsgames.co/animatiss
  Twitter: @xsgames_
---------------------------------------------- */

.scale-down-center{animation:scale-down-center 0.4s; } @keyframes scale-down-center{0%{transform:scale(1)}100%{transform:scale(.5)}}

.container__bank {
  margin-top: 11px;
}

.typography {
  color: #344776;
}
.authPage {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 72px 0;
  background: #fafafb;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
}

.wrapper {
  height: 221px;
  width: 685px;
  text-align: center;
  background-position-x: center;
  font-size: 14px;
}

.formContainer {
  min-height: 200px;
  width: 416px;
  margin: 14px auto;
  margin-bottom: 0;
  margin-top: 0.9rem;
  padding: 33px 15px 15px 15px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 #e3e9f3;
}

.row {
  text-align: start;
  margin-top: -11px;
}

a {
  color: #217AFE;
}

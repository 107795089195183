.container {
  margin-top: 11px;
}

.confirm__password__input {
    margin-bottom: 1px;
}

.errorsContainer {
  margin-bottom: 18px;
  color: #ff203c;
}
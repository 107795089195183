/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dadada;
    border-radius: 28px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
      background: #b4b4b4;
      border-radius: 28px;
  }